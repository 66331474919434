<template>
  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
</template>

<script setup></script>

<style scoped>
html.dark {
  color-scheme: dark;
}
</style>
