<template>
  <div class="flex-col items-center justify-center w-full bg-stone-100 pb-12 px-4 lg:px-0">
    <div v-if="windowSize > 640">
      <div class="my-16 text-center w-full">
        <ServicesHeader title="What We Do"></ServicesHeader>
      </div>
      <div class="w-full flex flex-col lg:grid lg:grid-cols-9">
        <div class="w-full"></div>
        <div class="flex col-span-7 justify-center w-full">
          <div
            class="grid grid-cols-2 gap-x-10 items-center"
          >
            <div class="flex flex-row justify-end">
              <img class="pb-10 align-right w-[500px] lg:w-[530px] shrink-0" :src="plant.filePath" />
            </div>
            <DescriptionText :textObject="plantText">
              <template v-slot:button>
                <LearnMoreButton link="#research" buttonContent="Learn More"></LearnMoreButton>
              </template>
            </DescriptionText>

            <DescriptionText :textObject="drillText">
              <template v-slot:button>
                <LearnMoreButton link="#strategies" buttonContent="Learn More"></LearnMoreButton>
              </template>
            </DescriptionText>
            <div class="flex flex-row justify-start">
              <img class="pb-10 w-[500px] lg:w-[530px] shrink-0" :src="drill.filePath" />
            </div>
            <div class="flex flex-row justify-end">
              <img class="pb-10  w-[500px] lg:w-[530px] shrink-0" :src="chicago.filePath" />
            </div>
            <DescriptionText :textObject="chicagoText">
              <template v-slot:button>
                <LearnMoreButton link="#strategies" buttonContent="Learn More"></LearnMoreButton>
              </template>
            </DescriptionText>
          </div>
        </div>
        <div class="w-full"></div>
      </div>
    </div>
    <div v-else class="bg-gray-100 px-0">
      <div class="text-center mt-16">
        <ServicesHeader title="What We Do"></ServicesHeader>
      </div>
      <div class="grid grid-cols-1 pt-10">
        <PhotoCard :cardText="plantText" :imageObject="plant">
          <template v-slot:button>
            <LearnMoreButton link="#research" buttonContent="Learn More"></LearnMoreButton>
          </template>
        </PhotoCard>
        <PhotoCard :cardText="drillText" :imageObject="drill">
          <template v-slot:button>
            <LearnMoreButton link="#strategies"  buttonContent="Learn More"></LearnMoreButton> </template
        ></PhotoCard>
        <PhotoCard :cardText="chicagoText" :imageObject="chicago">
          <template v-slot:button>
            <LearnMoreButton link="#strategies"  buttonContent="Learn More"></LearnMoreButton> </template
        ></PhotoCard>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";

import PhotoCard from "./PhotoCard.vue";
import LearnMoreButton from "@/components/our_services/what_we_do/LearnMoreButton.vue";
import DescriptionText from "./DescriptionText.vue";
import ServicesHeader from "../headers/ServicesHeader.vue";

const plant = ref({
  filePath: require("../../../assets/plant.png"),
});

const windowSize = ref(window.innerWidth);

onMounted(() => {
  window.addEventListener("resize", () => {
    windowSize.value = window.innerWidth;
  });
});
onUnmounted(() => {
  window.removeEventListener("resize", () => {
    windowSize.value = window.innerWidth;
  });
});

const plantText = ref({
  header: "Direct Investment",
  body: "Investing in critical components and key manufacturers to secure future growth.",
});

const drill = ref({
  filePath: require("../../../assets/drill.png"),
});

const drillText = ref({
  header: "Business Development",
  body: "Powerful consulting with a hands-on, personalized approach to fine-tune company initiatives and drive business growth.",
});

const chicago = ref({
  filePath: require("../../../assets/chicago.png"),
});

const chicagoText = ref({
  header: "Corporate Development",
  body: "Extensive network and multiple careers' worth of relationships and experience to guide clients and successfully target acquisitions.",
});
</script>
