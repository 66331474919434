<template>
  <div>
    <div class="flex items-center justify-center">
      <div class="flex px-4 lg:px-0  lg:max-w-[1100px]">
        <ul
          class="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-x-10 pt-10"
        >
          <div>
            <description-card :text-object="directInvestment">
              <template v-slot:header>
                <div class="font-[400] text-[22px] text-3xl mb-6 mt-12 tracking-wide text-left lg:text-center">
                  Direct Investment
                </div>
              </template>
              <template v-slot:body
                ><p
                  class="font-light text-[16px] text-slate-600 leading-relaxed break-words text-left lg:text-center"
                >
                  <br />
                  Shedd Capital seeks direct investments that have high
                  potential for growth in differentiated markets. We are firmly
                  committed to adding value to our partners by offering direct
                  guidance to meet business goals and drive growth.
                </p></template
              >
            </description-card>
          </div>

          <description-card :text-object="businessInvestment">
            <template v-slot:header>
                <div class="font-[400] text-[22px] text-3xl mb-6 mt-12 tracking-wide text-left lg:text-center">
                  Business Development
                </div>
            </template>
            <template v-slot:body
              ><p class="font-light text-[16px] text-slate-600 leading-relaxed text-left lg:text-center">
                <br />
                Our philosophy centers around serving the companies we invest
                in; these companies and their people are the driving force
                behind what we do. We are dedicated to our companies and our
                partners, and act with their best interests in mind.
                <br /><br />
                We work diligently to improve processes and establish
                profitable, sustainable business models that will continue to
                add value far into the future.
              </p></template
            >
          </description-card>
          <description-card :text-object="corporateDevelopment">
            <template v-slot:header>
                <div class="font-[400] text-[22px] text-3xl mb-6 mt-12 tracking-wide text-left lg:text-center">
                  Corporate Development
                </div>
            </template>
            <template v-slot:body
              ><p class="font-light text-[16px] text-slate-600 leading-relaxed text-left lg:text-center">
                <br />
                We provide unrivaled support in sourcing, analyzing, and
                executing valuable acquisitions for our clients. With
                cutting-edge research capabilities and a myriad of deep
                relationships within our network. <br />
                <br />
                Shedd Capital offers unique insights and a personalized strategy
                for partners seeking acquisitions within aerospace and defense
                industries. Our approach includes hands-on personal outreach,
                superior engagement,and structured campaign management to ensure
                successful outcome. <br /><br />
                As shareholders, we work tirelessly for the long-term success of
                our clients and the businesses they acquire.
              </p></template
            >
          </description-card>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import DescriptionCard from "./DescriptionCard.vue";
import ServicesHeader from "../headers/ServicesHeader.vue";

const directInvestment = ref({
  header: "Direct Investment",
});

const businessInvestment = ref({
  header: "Business Development",
  body: "",
});

const corporateDevelopment = ref({
  header: "Corporate Development",
  body: "",
});
</script>
