<template>
  <div class="flex flex-row justify-center w-full">
    <div class="pt-16 text-center bg-stone-100 w-full">
        <h2 class="text-3xl pb-4 lg:pb-20 px-3 pt-6">Contact Us</h2>
        <div class="w-full flex flex-col px-4 lg:px-0 lg:grid lg:grid-cols-9">
            <div class="w-full"></div>
            <div class="flex flex-row justify-center lg:col-span-7 w-full">
                <div class="flex flex-col lg:flex-row lg:space-x-10">
                    <div class="flex flex-row lg:justify-end items-center">
                        <img
                            class="pb-4 lg:pb-10 align-right w-[500px] lg:w-[530px] mx-auto shrink-0"
                            src="../assets/shedd-contact-info.png"
                            alt="smth"
                        />
                    </div>
                    <div class="flex flex-row lg:justify-start items-center">
                        <img
                            class="pb-4 lg:pb-10 align-right w-[500px] lg:w-[530px] mx-auto shrink-0"
                            src="../assets/dallas.png"
                            alt="smth"
                        />
                    </div>
                </div>
            </div>
            <div class="w-full"></div>
        </div>
    </div>
  </div>
</template>

<sctipt setup>

</sctipt>