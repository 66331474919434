import { createRouter, createWebHistory } from "vue-router";

import HomePage from './components/HomePage.vue';
import PageHolder from "./components/PageHolder.vue";
import OurServices from './pages/OurServices.vue';
import OurTeam from './components/OurTeam.vue';
import JoinUs from'./components/JoinUs.vue';
import BusinessDevelopmentAnalystI from '@/pages/job_postings/BusinessDevelopmentAnalystI.vue';
import PageNotFound from "./pages/PageNotFound.vue";


const routes = [
    {path: '/', component: PageHolder, name: 'home'},
    // {path: '/positions/business-development-analyist-i', component: BusinessDevelopmentAnalystI, name: 'business-development-analyist-i'},

];

//     {path: '/:pathMatch(.*)*', component: PageNotFound, name:"page-not-found"},

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;


