<template>
  <div id="team" class="flex flex-row justify-center">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-3 m-8 lg:m-2 lg:max-w-[1150px]">
      <team-member
        v-for="(teamMember, index) in teamMembers"
        :teamMember="teamMember"
        :key="index"
      ></team-member>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import TeamMember from "./TeamMember.vue";

const teamMembers = ref([
  {
    name: "Scott Shedd",
    headshot: require("../assets/headshots/scott_shedd.png"),
    position: "CEO",
    experience:
      "20+ years of aerospace & defense investment banking and private equity experience",
    degrees: [
      "MBA, Finance at Northwestern University - Kellogg School of Management",
      "BA, Computer Science at Indiana University Bloomington",
    ],
  },
  {
    name: "Norman Chui",
    headshot: require("../assets/headshots/norman_chui.png"),
    position: "CIO",
    experience:
      "20+ years of technology, operations and program management experience",
    degrees: [
      "MBA, Finance, Organizational Behavior, Entrepreneurship at The University of Chicago Booth School of Business",
      "BS, Computer Science at University of Illinois Urbana-Champaign",
    ],
  },
  // {
  //   name: "Matthew Strickler",
  //   headshot: require("../assets/headshots/matthew_strickler.png"),
  //   position: "Managing Director",
  //   experience: "20+ years of financial and business consulting",
  //   degrees: [
  //     "MBA, Strategy at Northwestern University - Kellogg School of Management",
  //     "Doctor of Law (JD) at Northwestern University Pritzker School of Law",
  //     "BS, International Relations, Aerospace Engineering at United States Military Academy at West Point",
  //   ],
  // },
  // {
  //   name: "Dan Klawitter",
  //   headshot: require("../assets/headshots/dan_klawitter.png"),
  //   position: "Vice President",
  //   experience:
  //     "8+ years of experience in investment banking, private equity, and corporate finance",
  //   degrees: [
  //     "BA, Finance, Investment Management, & Marketing at University of Wisconsin-Milwaukee",
  //   ],
  // },
  // {
  //   name: "Suzanne Strickler",
  //   headshot: require("../assets/headshots/suzanne_strickler.png"),
  //   position: "Senior Administrative Manager",
  //   experience: "",
  //   degrees: [
  //     "Doctor of Law (JD) at Chicago-Kent College of Law",
  //     "BA at Seton Hall University",
  //   ],
  // },
  {
    name: "Dylan Marvel",
    headshot: require("../assets/headshots/dylan_marvel.png"),
    position: "Software Engineer",
    experience: "",
    degrees: [
      "BS, Chemistry at University of Minnesota - College of Science and Engineering",
    ],
  },
  {
    name: "Patrick Alvarez",
    headshot: require("../assets/headshots/patrick_alvarez.png"),
    position: "Software Engineer",
    experience: "",
    degrees: ["BA, Economics at Florida State University"],
  },
  {
    name: "Brandon Ahearn",
    headshot: require("../assets/headshots/brandon_ahearn.png"),
    position: "Business Development Analyst",
    experience: "",
    degrees: ["BA, Finance at Southern Methodist University"],
  },
  {
    name: "Carter Link",
    headshot: require("../assets/headshots/carter_link.jpeg"),
    position: "Business Development Analyst",
    experience: "",
    degrees: ["BS, Economics with Financial Applications at Southern Methodist University"],
  },
  //   {
  //   name: "Tony Patsatzis",
  //   headshot: require("../assets/headshots/tony_patsatzis.png"),
  //   position: "Business Development Analyst",
  //   experience: "",
  //   degrees: ["BA, Finance at Southern Methodist University"],
  // },
]);
</script>
