<template>
  <div class="bg-stone-100 mt-10">
    <div class="pt-12 text-center mb-4 text-3xl font-normal">
        Research Capabilities
      <!-- <ServicesHeader title="Research Capabilities"></ServicesHeader> -->
    </div>
    
    <div class="flex items-center justify-center">
      <div class="w-full flex flex-col lg:grid lg:grid-cols-9 px-4 lg:px-0 ">
        <div class="w-full"></div>
        <div class="flex col-span-7 justify-center w-full">
          <div class="grid lg:grid-cols-3 md:grid-col-2 sm:grid-col-1 lg:gap-x-8 pt-10">
              <ResearchCardVue :cardText="dataText" :imageObject="dataImage"></ResearchCardVue>
              <ResearchCardVue :cardText="researchText" :imageObject="researchImage"></ResearchCardVue>
              <ResearchCardVue :cardText="geoText" :imageObject="geoImage"></ResearchCardVue>
          </div>
        </div>
        <div class="w-full"></div>
      </div>
    </div>
  </div>
</template>

<script setup>

import { ref } from 'vue';
import ResearchCardVue from './ResearchCard.vue';
import ServicesHeader from '../headers/ServicesHeader.vue'

const dataText = ref({
  header: "Data-Driven Offerings",
  body: "We maintain a vast internal database with 150,000+ company profiles at our disposal, allowing us to zero in on a wide array of prospective business targets.",
});

const dataImage = ref({
  filePath: require("../../../assets/wires.png"),
});

const geoImage = ref({
  filePath: require("../../../assets/book.png"),
});

const researchImage = ref({
  filePath: require("../../../assets/map.png"),
});

const researchText = ref({
  header: "Dedicated Research",
  body: "We utilize a dedicated team of researchers to collect and vet data in order to provide the highest quality information when making business decisions.",
});

const geoText = ref({
  header: "Geolocation Services",
  body: "Our geolocation services allow us to filter for potential targets within a given geographical scope.",
});

</script>
