<template>
  <div class="flex flex-row  justify-center w-full">
    <div class="lg:max-w-[1150px]">
      <h1>
        <h1 class="mb-2 lg:mb-10 text-3xl font-normal">
          {{ mainHeader }}
        </h1>
      </h1>
      <div class="flex justify-center">
        <div class="grid grid-cols-1 lg:grid-cols-3 lg:gap-x-8 lg:pt-10">
          <div class="flex justify-center">
            <div class="lg:w-4/5 w-[90%] text-center mt-10 lg:mt-16 mb-2 lg:mb-10">
              <h1 class="lg:text-xl h-[45px] text-xl mb-5">
                {{ firstHeader }}
              </h1>
              <p class="text-stone-500 sm:text-lg font-light">
                {{ firstContent }}
              </p>
            </div>
          </div>


          <div class="flex justify-center">
            <div class="lg:w-4/5 w-[90%] text-center mt-10 lg:mt-16 mb-2 lg:mb-10">
              <h1 class="lg:text-xl h-[45px] text-xl mb-5">
                {{ secondHeader }}
              </h1>
              <p class="text-stone-500 sm:text-lg font-light">
                {{ secondContent }}
              </p>
            </div>
          </div>


          <div class="flex justify-center">
            <div class="lg:w-4/5 w-[90%] text-center mt-10 lg:mt-16 mb-2 lg:mb-10">
              <h1 class="lg:text-xl h-[45px] text-xl mb-5">
                {{ thirdHeader }}
              </h1>
              <!-- <slot name="thirdContent">
              </slot> -->
              <div v-if="isList">
                <div class="text-stone-500 sm:text-lg font-light">
                  <ul class="list-disc lg:ml-10 ml-3">
                    <li v-for="item of thirdContent" :key="item">
                      {{ item }}
                    </li>
                  </ul>
                </div>
              </div>
              <div v-else>
                <p class="text-stone-500 sm:text-lg font-light">
                  {{ thirdContent }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
const props = defineProps({
  mainHeader: {
    type: String,
  },
  firstHeader: {
    type: String,
  },
  secondHeader: {
    type: String,
  },
  thirdHeader: {
    type: String,
  },
  firstContent: {
    type: String,
  },
  secondContent: {
    type: String,
  },
  thirdContent: {
    type: [String, Array],
    required: true,
  },
});

const isList = Array.isArray(props.thirdContent);
</script>
