<template>
  <div class="flex flex-row justify-center w-full">
    <div  v-if="jobPostings.length > 0" class="pt-16 text-center bg-stone-100 w-full ">
      <div id="heading">
        <h2 class="text-3xl pb-10 px-3 border-b border-stone-3">Join Our Team</h2>
      </div>
      <div class="flex flex-col items-center space-y-4">
        <JobCard
          v-for="(jobPosting, index) in jobPostings"
          :key="index"
          :job-posting="jobPosting"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import JobCard from "./join_us/JobCard.vue";


const jobPostings = ref([
  // {
  //   title: 'Business Development Analyst I',
  //   shortDescription: 'We have an entry level Business Development Analyst I position open on location in Dallas. We are seeking a motivated team player with exemplary research and communications skills who is eager to learn and quickly develop new skills.',
  //   routePath: '/positions/business-development-analyist-i',
  // },
])

</script>
